import Vue from 'vue'
import App from './App.vue'

// import './assets/iconfont/iconfont.css'

// 全局样式
import './styles/index.less'

import router from '@/router'

Vue.config.productionTip = false

new Vue({
  router,
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  },
  render: h => h(App)
}).$mount('#app')
